import { useEffect, useState } from 'react';

import { appActions } from '@actions';
import { Design } from '@components';
import { ActiveTab, getRegion } from '@helpers';
import { Region } from '@models';
import { DeliverTo } from '@modules';
import { appSelectors } from '@selectors';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import styles from './header.module.scss';
import DesktopHeaderNavbar from './navbar/navbar.module';
import UserControls from './user-controls/user-controls.module';

const DynamicSearchBox = dynamic(
  () => import('../search-box/search-box.module')
);

const AuthModal = dynamic(() => import('../../auth/auth-modal'), {
  ssr: false,
});

const DesktopHeader = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [showNavbar, setShowNavbar] = useState(true);
  const [isHeaderAtTop, setIsHeaderAtTop] = useState(true);

  let _categoryMenuHeight = 325;
  let lastScrollTop = 0;

  useEffect(() => {
    if (location.pathname === '/') {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      const MegaMenuContainer = document.getElementById('MegaMenu');

      if (MegaMenuContainer) {
        const MegaMenuBottomPosition =
          MegaMenuContainer.getBoundingClientRect().top +
          MegaMenuContainer.getBoundingClientRect().height;

        _categoryMenuHeight = MegaMenuBottomPosition - 125;

        if (scrollTop > MegaMenuBottomPosition) {
          setIsHeaderAtTop(false);
          setShowNavbar(false);
        }
      }
    } else {
      setIsHeaderAtTop(false);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      document.body.classList.remove('overlay-active');
      if (url !== '/') {
        setIsHeaderAtTop(false);
      } else {
        setIsHeaderAtTop(true);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  const handleScroll = () => {
    var st = window.pageYOffset || document.documentElement.scrollTop;

    if (location.pathname === '/')
      setIsHeaderAtTop(!(st > _categoryMenuHeight));

    if (st > lastScrollTop) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
    lastScrollTop = st <= 0 ? 0 : st;
  };

  const handleShowOverlay = (state: boolean) => {
    if (state) document.body.classList.add('overlay-active');
    else document.body.classList.remove('overlay-active');
  };

  const handleVisibleAuthModal = () =>
    dispatch(appActions.toggleAuthModal(true));

  return (
    <>
      <header className="heder-module">
        <div className={styles.InnerContainer}>
          <div className="container">
            <div className={styles.AlwaysFixedHeader}>
              <div className={styles.LogoContainer}>
                <Link href="/" prefetch={false}>
                  <a>
                    {getRegion() === Region.AR_QA && (
                      <h1>Buy Fashion and Beauty Products Online in QATAR</h1>
                    )}
                    {getRegion() === Region.AR_AE && (
                      <h1>Buy Fashion and Beauty Products Online in UAE</h1>
                    )}
                  </a>
                </Link>
              </div>
              {/* <Design minWidth="109px" width="9%" margin="0 20px">
                <DeliverTo />
              </Design> */}
              <div className={styles.SearchBoxContainer}>
                <DynamicSearchBox
                  showOverlay={handleShowOverlay}
                  autoFocus={false}
                />
              </div>
              <div className={styles.UtilitiesContainer}>
                <UserControls onVisibleAuthModal={handleVisibleAuthModal} />
              </div>
            </div>
          </div>
        </div>

        <DesktopHeaderNavbar
          showCategoryMenu={!isHeaderAtTop}
          visibility={showNavbar}
        />

        <AuthModal />
      </header>
    </>
  );
};

export default DesktopHeader;
