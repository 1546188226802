import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Button } from 'antd';
import ds from '@ds';
import cn from 'clsx';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { getAuth } from 'firebase/auth';
import { Heart, ShoppingCart, User } from 'react-feather';

import {
  appActions,
  cartActions,
  profileActions,
  wishlistActions,
} from '@actions';
import { Design } from '@components';
import { getRegionToken, handleSignOut, purePhoneNumber } from '@helpers';
import { usePromise, useStorage } from '@hooks';
import { AddressRegionSelected } from '@models';
import { SpinnerModule } from '@modules';
import {
  appSelectors,
  cartSelectors,
  wishlistSelectors,
  firebaseSelectors,
} from '@selectors';

import classes from './user-controls.module.scss';

type Props = {
  onVisibleAuthModal: (mode: string) => void;
};

const UserControls = ({ onVisibleAuthModal }: Props) => {
  const promise = usePromise();

  const autoInfo = useSelector(firebaseSelectors?.auth);

  const visibleAreaModal = useSelector(appSelectors.visibleAreaModal);
  const regionUpdating = useSelector(appSelectors.regionUpdating);

  const { localData: currentRegion, removeLocalStorage } =
    useStorage<AddressRegionSelected>(
      getRegionToken(),
      regionUpdating || visibleAreaModal
    );

  const { currentUser } = getAuth();
  const isAnonymous = currentUser?.isAnonymous ?? true;

  const visiblePopupCart = useSelector(appSelectors.visiblePopupCart);
  const { data: wishlistCompact } = useSelector(wishlistSelectors.compact);
  const { data: cartCompact } = useSelector(cartSelectors.compact);

  const [logoutLoading, setLogoutLoading] = useState<boolean>(false);

  const handleLogout = () => {
    setLogoutLoading(true);

    handleSignOut().then(() => {
      removeLocalStorage();
      Promise.all([
        promise(profileActions.loadProfile()),
        promise(cartActions.loadCartCompact(currentRegion?.area?.id)),
        promise(wishlistActions.compactWishlist()),
      ]).finally(() =>
        setTimeout(() => {
          setLogoutLoading(false);
        }, 2000)
      );
    });
  };

  const handlePopupCartVisibility = (state: boolean) => {
    promise(appActions.togglePopupCart(state));
  };

  const renderAccountDropdown = () => {
    if (!isAnonymous) return renderAuthorizedDropdown();
    return renderAnonymousDropdown();
  };

  const accountText = useMemo(() => {
    if (currentUser?.displayName)
      return {
        title: currentUser?.displayName,
        link: 'Your Account',
        isPhone: false,
      };
    return {
      title: currentUser?.phoneNumber ?? '',
      link: 'Complete Account Info',
      isPhone: currentUser?.phoneNumber ? true : false,
    };
  }, [currentUser?.displayName, currentUser?.phoneNumber, currentUser?.email]);

  const accountButtonTitle = (text: string, isPhone: boolean) => {
    if (isAnonymous) return 'Account';
    if (isPhone) return purePhoneNumber(text);
    if (text.length > 7) return text.split('').splice(0, 7).join('') + '...';
    return text;
  };

  const cartTotalQty =
    cartCompact?.items?.reduce((prev, item) => prev + item.qty, 0) ?? 0;

  const renderAnonymousDropdown = () => {
    return (
      <div className={classes.AnonymousDropdown}>
        <p>Hello! Welcome to 1Sell</p>
        <Button type="primary" onClick={() => onVisibleAuthModal('register')}>
          Login/Register
        </Button>
      </div>
    );
  };

  const renderAuthorizedDropdown = () => {
    return (
      <div className={classes.AuthorizedDropdown}>
        <header>
          <div className={classes.UserAvatar}>
            <User color={ds.color_background} />
          </div>

          <div>
            <p>{accountText.title}</p>
            <Link prefetch={false} href={'/profile/personal-info'}>
              {accountText.link}
            </Link>
          </div>
        </header>
        <div className={classes.LoggedInActions}>
          <Link prefetch={false} href={'/profile/orders'}>
            <a className={classes.UserOrdersAction}>Your Orders</a>
          </Link>
          <button
            type="button"
            className={classes.SignOutButton}
            onClick={handleLogout}
          >
            Sign out
          </button>
        </div>

        <div
          className={cn(classes.LogoutSpinner, {
            [classes.ActiveLogoutSpinner]: logoutLoading,
          })}
        >
          <SpinnerModule />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classes.Container}>
        <li>
          <a
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <div className={classes.AccountBtn}>
              <User width={24} height={24} />
              {accountButtonTitle(accountText.title, accountText.isPhone)}
            </div>
            <div className={classes.UserDropdown}>
              {renderAccountDropdown()}
            </div>
          </a>
        </li>

        <li>
          <Link
            prefetch={false}
            href={isAnonymous ? '/wishlist' : '/profile/wishlist'}
          >
            <a>
              {!!wishlistCompact?.products_count &&
                wishlistCompact?.products_count > 0 && (
                  <Design
                    position="absolute"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.CountBadge}
                  >
                    <Badge count={wishlistCompact?.products_count} />
                  </Design>
                )}

              <Heart width={16} height={16} />
            </a>
          </Link>
        </li>

        <li
          className={classes.CartButtonContainer}
          onMouseEnter={() => handlePopupCartVisibility(true)}
          onMouseLeave={() => handlePopupCartVisibility(false)}
        >
          <Link href={'/cart'} prefetch={false}>
            <a
              className={`${visiblePopupCart === true ? classes.Active : ''}`}
              onClick={() => handlePopupCartVisibility(false)}
            >
              {!!cartTotalQty && cartTotalQty > 0 && (
                <Design
                  position="absolute"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.CountBadge}
                >
                  <Badge count={cartTotalQty} />
                </Design>
              )}

              <ShoppingCart width={16} height={16} />
            </a>
          </Link>
        </li>
      </div>
    </>
  );
};

export default UserControls;
