import React, { FC, useEffect, useState } from 'react';

import { headerLinksSelectors } from '@selectors';
import Image from 'next/image';
import Link from 'next/link';
import { Menu } from 'react-feather';
import { useSelector } from 'react-redux';
import MegaMenuModule from 'src/modules/mega-menu';

import styles from './navbar.module.scss';

interface Props {
  visibility: boolean;
  showCategoryMenu: boolean;
}

const DesktopHeaderNavbar: FC<Props> = ({ visibility, showCategoryMenu }) => {
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const { data: headerLinksData, fetching } = useSelector(
    headerLinksSelectors.selectAll
  );

  const renderExtraLinks = () => {
    return headerLinksData?.map((link) => {
      const isExternalLink = link?.referred_url?.includes('http');

      let clickableLink = (
        <Link href={link.referred_url} prefetch={false}>
          <a>
            <Image
              width={24}
              height={24}
              src={link.icon.urls.original}
              alt={link.title}
            />
            {link.title}
          </a>
        </Link>
      );

      if (isExternalLink)
        clickableLink = (
          <a href={link.referred_url}>
            <Image
              width={24}
              height={24}
              src={link.icon.urls.original}
              alt={link.title}
            />
            {link.title}
          </a>
        );

      return <li key={link.title}>{clickableLink}</li>;
    });
  };

  useEffect(() => {
    if (!showCategoryMenu) {
      document.body.classList.remove('overlay-active');
      setShowMegaMenu(false);
    }
  }, [showCategoryMenu]);

  const handleCategoryMenuMouseEnter = () => {
    document.body.classList.add('overlay-active');
    setShowMegaMenu(true);
  };

  const handleCategoryMenuMouseLeave = () => {
    document.body.classList.remove('overlay-active');
    setShowMegaMenu(false);
  };

  return (
    <nav className={`${styles.Navbar} ${visibility ? styles.Active : ''}`}>
      <div className="container">
        <ul className={styles['navbar-items']}>
          <li
            className={
              showCategoryMenu
                ? `${styles.CategoryMenu} ${styles.ExpandedCategoryMenu}`
                : styles.CategoryMenu
            }
          >
            <a
              className={showMegaMenu ? 'active-hover' : ''}
              onMouseOver={handleCategoryMenuMouseEnter}
              onMouseLeave={handleCategoryMenuMouseLeave}
              onClick={handleCategoryMenuMouseEnter}
            >
              <Menu width={24} height={24} />
              Categories
              <div
                className={`${styles.MegaMenuScrollingContainer} ${
                  showMegaMenu ? styles.ActiveMegaMenu : ''
                }`}
                onMouseLeave={handleCategoryMenuMouseLeave}
              >
                {showCategoryMenu && <MegaMenuModule isOnStickyNavbar={true} />}
              </div>
            </a>
          </li>
          {renderExtraLinks()}
          <li>
            <a href="" onClick={(e) => e.preventDefault()}>
              FAQ/Support
            </a>

            <ul>
              <li>
                <Link href="/return-policy" prefetch={false}>
                  <a>{'Refund & Return'}</a>
                </Link>
              </li>
              <li>
                <Link href="/faq" prefetch={false}>
                  <a>FAQ</a>
                </Link>
              </li>
              <li>
                <Link href="/privacy-policy" prefetch={false}>
                  <a>{'Privacy & Policy'}</a>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default DesktopHeaderNavbar;
